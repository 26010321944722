<template>
    <!-- 这里之所以添加preventDefault，是因为浏览器在拖拽的时候页面会左右上下滑动 -->
    <section
        @touchmove="
            (e) => {
                e.stopPropagation();
                if (!createdImgShow) {
                    e.preventDefault();
                }
            }
        "
    >
        <Container
            v-if="data && data.length > 0"
            :data="data"
            @showPreviewImg="show"
            @hidePreviewImg="hide"
            @updatePageSize="
                ({ pageSize, appendSpaceNum }) =>
                    onLoad({ pageSize, appendSpaceNum })
            "
        ></Container>
        <!-- 保存和分享的时候图片预览 -->
        <div v-if="createdImgShow" class="img-for-download">
            <img id="imgForDownload" style="width: 100%; display: block" />
            <div class="save-share">长按图片，可以保存分享</div>
        </div>
    </section>
</template>

<script>
import Container from "./ContainerSeven";
export default {
    components: {
        Container,
    },
    data() {
        return {
            createdImgShow: false,
            // pageSize: 30,
            pageSize: sessionStorage.getItem("pageSize") ? Number(sessionStorage.getItem("pageSize")) : 30,
            data: []
        };
    },
    methods: {
        show() {
            this.createdImgShow = true;
        },
        hide() {
            this.createdImgShow = false;
        },
        onLoad({ pageSize = 30, appendSpaceNum = 4 }) {
            this.data = [];
            this.$nextTick(async () => {
                const res = await this.$HTTP.post(
                    this,
                    "API_LOTTERY_LISTS_SPACE",
                    {
                        // lotteryKind: this.$store.state.common.lotteryKind,
                        lotteryKind: "7",
                        pageNo: "1",
                        pageSize, //"30",
                        appendSpaceNum: appendSpaceNum,
                    },
                    {},
                    true
                );
                // alert(JSON.stringify(res.data))
                console.log(JSON.stringify(res.data));
                this.data = res.data; //.reverse();
            });
        },
        // updatePageSize(pageSize) {
        //     this.pageSize = pageSize;
        //     this.$nextTick(() => {
        //         this.onLoad({});
        //     });
        // },
    },
    created() {
        this.onLoad({pageSize: this.pageSize});
    },
};
</script>

<style lang="less" scoped>
.img-for-download {
    position: fixed;
    left: 0;
    top: 40px;
    z-index: 299;
    width: 100%;
    height: 100vh;
    overflow: auto;
}
.save-share {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: 2;
}
</style>