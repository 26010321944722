<template>
    <div>
        <DrawingRules />
    </div>
</template>

<script>
import DrawingRules from "@/components/DrawingRules/indexSeven";
export default {
    components: { DrawingRules },
};
</script>

<style lang="scss" scoped>
</style>