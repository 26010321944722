<template>
    <div id="allContainerSeven" class="drawing-rules relative">
        <Tool
            @close="close"
            @revoke="revoke"
            @recovery="recovery"
            @clear="clear"
            @share="share"
            bgGreen
            @updatePageSize="
                (pageSize) => {
                    this.pageSize = pageSize;
                    this.$emit('updatePageSize', pageSize);
                }
            "
            v-show="forSaving"
        />
        <div v-show="!forSaving1" class="back-btn" @click="backToEdit">
            <van-icon name="arrow-left" />
            <span>返回</span>
        </div>
        <div
            v-show="!forSaving"
            style="border-bottom: 4px solid #90c282; margin-top: 46px"
        >
            <div style="height: 52px"></div>
            <img :src="PaiLie5Top" style="width: 100%; display: block" />
        </div>
        <div
            class="relative"
            @touchstart="touchstart"
            @touchmove="touchmoveAll"
            @touchend="touchend"
        >
            <table class="w100" id="tableBall">
                <thead class="purple title">
                    <tr>
                        <th
                            style="
                                border-left-color: #90c282;
                                border-bottom-color: #90c282;
                            "
                            id="firstTh"
                        >
                            期号
                        </th>
                        <th class="w1" style="border-bottom-color: #90c282">
                            值
                        </th>
                        <th class="w1" style="border-bottom-color: #90c282">
                            A
                        </th>
                        <th class="w1" style="border-bottom-color: #90c282">
                            B
                        </th>
                        <th class="w1" style="border-bottom-color: #90c282">
                            C
                        </th>
                        <th class="w1" style="border-bottom-color: #90c282">
                            D
                        </th>
                        <th
                            class="w1"
                            style="
                                border-right-color: #90c282;
                                border-bottom-color: #90c282;
                            "
                        >
                            E
                        </th>
                        <th class="w1" style="border-bottom-color: #90c282">
                            F
                        </th>
                        <th class="w1" style="border-bottom-color: #90c282">
                            G
                        </th>
                    </tr>
                </thead>
                <tbody
                    v-for="(arr, i) in dataTwo"
                    :key="i"
                    :class="{ 'light-purple': i % 2 === 0 }"
                >
                    <tr v-for="(item, j) in arr" :key="j">
                        <td class="first-td">
                            <span
                                class="first-td-span"
                                >{{ item.periodText }}</span
                            >
                            <span
                                >{{
                                    moment(item.lotteryDate).format("MM月DD日")
                                }}</span
                            >
                        </td>
                        <td style="border-right-width: 2px" class="td-0-bggreen td-second">
                            <!-- <div class="ball">{{ item.lotteryVu }}</div> -->
                            <!-- <div :index="i * 24 + j * 6 + 0"> -->
                            <div>
                                <span class="font-div td-second-one">{{
                                    item.lotteryVuNew || item.lotteryVu
                                }}</span>
                                <span
                                    class="font-div td-second-two"
                                    >星期{{
                                        weekMapping[
                                            moment(item.lotteryDate).format("E")
                                        ]
                                    }}</span
                                >
                            </div>
                        </td>
                        <td>
                            <!-- <div :index="i * 24 + j * 6 + 1">{{ item.num1 }}</div> -->
                            <div class="font-div">{{ item.num1New || item.num1 }}</div>
                        </td>
                        <td>
                            <!-- <div :index="i * 24 + j * 6 + 2">{{ item.num2 }}</div> -->
                            <div class="font-div">{{ item.num2New || item.num2 }}</div>
                        </td>
                        <td>
                            <!-- <div :index="i * 24 + j * 6 + 3">{{ item.num3 }}</div> -->
                            <div class="font-div">{{ item.num3New || item.num3 }}</div>
                        </td>
                        <td style="border-right-width: 2px">
                            <!-- <div :index="i * 24 + j * 6 + 4">{{ item.num4 }}</div> -->
                            <div class="font-div">{{ item.num4New || item.num4 }}</div>
                        </td>
                        <!-- <td style="background-color: #eff9ca"> -->
                        <td class="td-0-bggreen">
                            <!-- <div :index="i * 24 + j * 6 + 5">{{ item.num5 }}</div> -->
                            <div  class="font-div">{{ item.num5New || item.num5 }}</div>
                        </td>
                        <!-- <td style="background-color: #eff9ca"> -->
                        <td class="td-0-bggreen">
                            <!-- <div :index="i * 24 + j * 6 + 5">{{ item.num5 }}</div> -->
                            <div  class="font-div">{{ item.num6New || item.num6 }}</div>
                        </td>
                        <!-- <td style="background-color: #eff9ca"> -->
                        <td class="td-0-bggreen">
                            <!-- <div :index="i * 24 + j * 6 + 5">{{ item.num5 }}</div> -->
                            <div  class="font-div">{{ item.num7New || item.num7 }}</div>
                        </td>
                    </tr>
                </tbody>
                <!-- <tbody
                    v-show="!forSaving"
                    v-for="(arr, i) in dataThree"
                    :key="i"
                >
                    <tr v-for="(item, j) in arr" :key="j">
                        <td class="first-td">
                            <span
                                className="mb5"
                                style="
                                    font-size: 12px;
                                    font-weight: bold;
                                    margin-bottom: 6px;
                                "
                                >{{ item.periodText }}</span
                            >
                            <span
                                style="
                                    font-size: 10px;
                                    font-weight: bold;
                                    white-space: nowrap;
                                "
                                >{{
                                    moment(item.lotteryDate).format("MM月DD日")
                                }}</span
                            >
                        </td>
                        <td style="border-right-width: 2px">
                            <div>
                                <span className="mb5">{{
                                    item.lotteryVu
                                }}</span>
                                <span
                                    style="font-size: 10px; white-space: nowrap"
                                    >星期{{
                                        weekMapping[
                                            moment(item.lotteryDate).format("E")
                                        ]
                                    }}</span
                                >
                            </div>
                        </td>
                        <td>
                            <div>{{ item.num1 || item.newName }}</div>
                        </td>
                        <td>
                            <div>{{ item.num2 || item.newName }}</div>
                        </td>
                        <td>
                            <div>{{ item.num3 || item.newName }}</div>
                        </td>
                        <td style="border-right-width: 2px">
                            <div>{{ item.num4 || item.newName }}</div>
                        </td>
                        <td>
                            <div>{{ item.num5 || item.newName }}</div>
                        </td>
                    </tr>
                </tbody> -->
            </table>
            <!-- <div class="td-0-bggreen"></div><div class="td-1-bggreen"></div> -->
            <div
                class="left-scroll-div"
                @touchstart="
                    (e) => {
                        this.isTouchMoveLeftSide = true;
                        e.stopPropagation();
                        // e.preventDefault();
                    }
                "
                @touchmove="
                    (e) => {
                        this.isTouchMoveLeftSide = false;
                        e.stopPropagation();
                        // e.preventDefault();
                    }
                "
                @touchend="
                    (e) => {
                        this.isTouchMoveLeftSide = false;
                        e.stopPropagation();
                        // e.preventDefault();
                    }
                "
            ></div>
            <img
                id="drgImgDom"
                :src="Drag"
                class="dr-drages-icon"
                v-show="displayDragImg"
                @touchstart="
                    (e) => {
                        this.dragStart(e);
                        this.isTouchMoveLeftSide = true;
                        e.stopPropagation();
                        // e.preventDefault();
                    }
                "
                @touchmove="
                    (e) => {
                        this.dragImgMove(e);
                        e.stopPropagation();
                        e.preventDefault();
                    }
                "
                @touchend="
                    (e) => {
                        this.dragEnd(e);
                        this.isTouchMoveLeftSide = false;
                        e.stopPropagation();
                        // e.preventDefault();
                    }
                "
            />
            <SvgComponent ref="svgRef" />
            <ColorSelector
                v-show="forSaving"
                @colorHandler="colorHandler"
                @lineWidthHandler="lineWidthHandler"
            />
            <div v-show="!forSaving">
                <img :src="PaiLie5Bottom" style="width: 100%; display: block" />
            </div>
        </div>
        <DrawSelect
            @updateDrawType="updateDrawType"
            @updateDrawType1="updateDrawType1"
            v-show="forSaving"
        />
        <NumberSelector
            ref="numbserSelector"
            :pageSize="pageSize"
            :index="numbserSelectorIndex"
            :show.sync="numbserSelectorVisible"
            @select="selectNumber"
        />
    </div>
</template>

<script>
import Tool from "./Tool";
import SvgComponent from "./Svg";
import util from "./util";
import { throttle } from "lodash";
import Drag from "./images/Drag.svg";
import ColorSelector from "./ColorSelector";
import Step from "./Step";
import moment from "moment";
import PaiLie5Top from "./images/header7.jpg";
import PaiLie5Bottom from "./images/footer7.jpg";
import html2canvas from "html2canvas";
import DrawSelect from "./DrawSelect";
import selectType from "./DrawSelectType";
import createCurveMixin from "./mixin/createCurve";
import createLineMixin from "./mixin/createLine";
import createFreedomLineMixin from "./mixin/createFreedomLine";
import createCircle from "./mixin/createCircle";
import createRectMixin from "./mixin/createRect";
import createRubberMixin from "./mixin/createRubber";
import NumberSelector from "./NumberSelector";

const {
    pageLockScroll,
    getCenterXY,
    getItemWH,
    getIndexBy,
    showBall,
    hideBall,
    setCurveQXY,
    getRem,
    createLineByInstance,
    removeLine,
    convertCanvasToImage,
    getRByPointStartEnd,
    set16ToRgb,
    isFutureFour,
    hasSelectedBall,
    hideFutureBall,
    hideBallSeven
} = util;

let oTop = 0;
let oLeft = 0;
let ODragImgTop = 0;
let divW = 0; // 每个格子的宽度
let divH = 0; // 每个格子的高度
export default {
    components: {
        SvgComponent,
        ColorSelector,
        Tool,
        DrawSelect,
        NumberSelector,
    },
    mixins: [
        createCurveMixin,
        createLineMixin,
        createFreedomLineMixin,
        createCircle,
        createRectMixin,
        createRubberMixin,
    ],
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dataTwo: [],
            dataThree: [], // 用来装空数据
            line: null,
            isTouchMoveLeftSide: false,
            allContainerSeven: null,
            centerXYArr: [],
            divDomArr: [],

            moveBalls: {}, // 移动时覆盖过得球
            touchstartIndex: 0,
            startXY: [],

            displayDragImg: false,
            Drag,
            drgImgDom: null,

            lineColor: "#409EFF",
            lineWidth: 2,

            moment,
            weekMapping: {
                1: "一",
                2: "二",
                3: "三",
                4: "四",
                5: "五",
                6: "六",
                7: "日",
            },

            forSaving: true,
            forSaving1: true,
            PaiLie5Top,
            PaiLie5Bottom,

            createdImgShow: false,
            drawType: "filledCircle",
            currentLineType: "filledCircle",
            currentLineType1: "intelligentLine",

            numbserSelectorVisible: false,
            numbserSelectorIndex: 0,

            // pageSize: 30,
            pageSize: sessionStorage.getItem("pageSize") ? Number(sessionStorage.getItem("pageSize")) : 30,
        };
    },
    watch: {
        forSaving(newValue) {
            if (newValue === false) {
                setTimeout(() => {
                    this.forSaving1 = newValue;
                }, 350);
            } else {
                this.forSaving1 = newValue;
            }
        },
    },
    methods: {
        log(index) {
            // index);
        },
        updateDrawType(drawType) {
            // this.drawType = drawType;
            this.toggleDragImg(false);
            // 智能线
            if (drawType === "intelligentLine") {
                // this.currentLineType = drawType;
                // 直线
            } else if (drawType === "straightLine") {
                // this.currentLineType = drawType;
            } else {
                this.drawType = drawType;
                this.toggleDragImg(false);
            }
        },
        updateDrawType1(drawType) {
            // this.drawType = drawType;
            // this.toggleDragImg(false);
            // 智能线
            if (drawType === "intelligentLine") {
                this.currentLineType1 = drawType;
                // 直线
            } else if (drawType === "straightLine") {
                this.currentLineType1 = drawType;
            } else {
                // this.drawType = drawType;
                // this.toggleDragImg(false);
            }
        },
        touchstart(e) {
            if (this.isTouchMoveLeftSide) {
                return;
            }
            pageLockScroll(true);
            try {
                const { changedTouches } = e;
                const { pageX, pageY } = changedTouches[0];
                const [centerX, centerY, index] = getIndexBy(
                    this.centerXYArr,
                    pageX - oLeft,
                    pageY - oTop,
                    divW,
                    divH
                );
                if (!isFutureFour(index, this.pageSize)) {
                    if (selectType.FILL_CIRCLE === this.drawType) {
                        const rgb = set16ToRgb(this.lineColor);
                        this.createCircle({
                            centerX,
                            centerY,
                            index,
                            fill: `rgba(${rgb}, 0.3)`,
                        });
                    } else if (selectType.HOLLOW_CIRCLE === this.drawType) {
                        // const rgb = set16ToRgb(this.lineColor);
                        // this.createCircle({
                        //     x: pageX - oLeft,
                        //     y: pageY - oTop,
                        //     r: 0,
                        //     index,
                        // });
                        this.createCircle({
                            centerX,
                            centerY,
                            index,
                            isFilled: false,
                        });
                    } else if (selectType.FILL_REACTANGLE === this.drawType) {
                        const rgb = set16ToRgb(this.lineColor);
                        this.createRect({
                            centerX,
                            centerY,
                            index,
                            // fill: `rgba(${rgb}, 0.3)`,
                        });
                    } else if (selectType.HOLLOW_REACTANGLE === this.drawType) {
                        this.createRect({
                            centerX,
                            centerY,
                            index,
                            isFilled: false,
                        });
                    } else if (selectType.RUBBER === this.drawType) {
                        // this.createCurve({ centerX, centerY, index });
                        this.createRubber({
                            x: pageX - oLeft,
                            y: pageY - oTop,
                            index,
                        });
                    }
                }
                if (isFutureFour(index, this.pageSize)) {
                    if (
                        selectType.FILL_CIRCLE === this.drawType ||
                        selectType.FILL_REACTANGLE === this.drawType ||
                        selectType.HOLLOW_CIRCLE === this.drawType ||
                        selectType.HOLLOW_REACTANGLE === this.drawType
                    ) {
                        // 如果是未来四期就创建矩形
                        this.createRect({
                            centerX,
                            centerY,
                            index,
                        });
                    }
                }
                // 智能线
                if (selectType.INTELLIGENT_LINE === this.drawType) {
                    // this.createCurve({ centerX, centerY, index });
                    this.createCurve({
                        centerX: pageX - oLeft,
                        centerY: pageY - oTop,
                        index,
                    });
                    // 直线
                } else if (selectType.STRAIGHT_LINE === this.drawType) {
                    this.createline({
                        x: pageX - oLeft,
                        y: pageY - oTop,
                        index,
                    });
                    // 自由线
                } else if (selectType.FREEDOM_LINE === this.drawType) {
                    this.createFreedomLine({
                        x: pageX - oLeft,
                        y: pageY - oTop,
                        index,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        touchmoveAll: function (e) {
            // 自由线防抖降低，为了自由线的连贯性
            if (selectType.FREEDOM_LINE === this.drawType) {
                return this.touchmove1(e);
            }
            return this.touchmove(e);
        },
        touchmove: throttle(function (e) {
            if (this.isTouchMoveLeftSide) {
                return;
            }
            try {
                const { changedTouches } = e;
                const { pageX, pageY } = changedTouches[0];
                const [centerX, centerY, index] = getIndexBy(
                    this.centerXYArr,
                    pageX - oLeft,
                    pageY - oTop,
                    divW,
                    divH
                );
                // 表示不是未来四期
                if (!isFutureFour(index, this.pageSize)) {
                    if (selectType.FILL_CIRCLE === this.drawType) {
                        // const x1 = pageX - oLeft;
                        // const y1 = pageY - oTop;
                        // const r = getRByPointStartEnd(
                        //     {
                        //         x: this.startXY[0],
                        //         y: this.startXY[1],
                        //     },
                        //     {
                        //         x: x1,
                        //         y: y1,
                        //     }
                        // );
                        // this.moveCircle({ r, x1, y1 });
                        this.moveCircle({
                            e,
                            oLeft,
                            oTop,
                            divW,
                            divH,
                            ODragImgTop,
                        });
                    } else if (selectType.HOLLOW_CIRCLE === this.drawType) {
                        // const x1 = pageX - oLeft;
                        // const y1 = pageY - oTop;
                        // const r = getRByPointStartEnd(
                        //     {
                        //         x: this.startXY[0],
                        //         y: this.startXY[1],
                        //     },
                        //     {
                        //         x: x1,
                        //         y: y1,
                        //     }
                        // );
                        // this.moveCircle({ r, x1, y1 });
                        this.moveCircle({
                            e,
                            oLeft,
                            oTop,
                            divW,
                            divH,
                            ODragImgTop,
                            isFilled: false,
                        });
                    } else if (selectType.FILL_REACTANGLE === this.drawType) {
                        // const x1 = pageX - oLeft;
                        // const y1 = pageY - oTop;
                        this.moveRect({
                            e,
                            oLeft,
                            oTop,
                            divW,
                            divH,
                            ODragImgTop,
                        });
                    } else if (selectType.HOLLOW_REACTANGLE === this.drawType) {
                        // const x1 = pageX - oLeft;
                        // const y1 = pageY - oTop;
                        this.moveRect({
                            e,
                            oLeft,
                            oTop,
                            divW,
                            divH,
                            ODragImgTop,
                            isFilled: false,
                        });
                    } else if (selectType.RUBBER === this.drawType) {
                        this.moveRubberLine({
                            x: pageX - oLeft,
                            y: pageY - oTop,
                        });
                    }
                } else {
                    if (
                        selectType.FILL_CIRCLE === this.drawType ||
                        selectType.FILL_REACTANGLE === this.drawType ||
                        selectType.HOLLOW_CIRCLE === this.drawType ||
                        selectType.HOLLOW_REACTANGLE === this.drawType
                    ) {
                        this.moveRect({
                            e,
                            oLeft,
                            oTop,
                            divW,
                            divH,
                            ODragImgTop,
                        });
                    }
                }
                // 智能线
                if (selectType.INTELLIGENT_LINE === this.drawType) {
                    this.moveCurve({
                        e,
                        oLeft,
                        oTop,
                        divW,
                        divH,
                        ODragImgTop,
                    });
                    // 直线
                } else if (selectType.STRAIGHT_LINE === this.drawType) {
                    this.moveLine({ x2: pageX - oLeft, y2: pageY - oTop });
                    // 自由线
                } else if (selectType.FREEDOM_LINE === this.drawType) {
                    this.moveFreedomLine({
                        x: pageX - oLeft,
                        y: pageY - oTop,
                    });
                }
            } catch (error) {
                console.log(error);
            }
        }, 40),
        touchmove1: throttle(function (e) {
            if (this.isTouchMoveLeftSide) {
                return;
            }
            try {
                const { changedTouches } = e;
                const { pageX, pageY } = changedTouches[0];
                if (selectType.FREEDOM_LINE === this.drawType) {
                    this.moveFreedomLine({ x: pageX - oLeft, y: pageY - oTop });
                }
            } catch (error) {
                console.log(error);
            }
        }, 10),
        touchend(e) {
            pageLockScroll(false);
            if (this.isTouchMoveLeftSide) {
                return;
            }
            const { changedTouches } = e;
            const { pageX, pageY } = changedTouches[0];
            const [centerX, centerY, index] = getIndexBy(
                this.centerXYArr,
                pageX - oLeft,
                pageY - oTop,
                divW,
                divH
            );
            // 表示不是未来四期
            if (!isFutureFour(index, this.pageSize)) {
                if (selectType.FILL_CIRCLE === this.drawType) {
                    this.moveCircleEnd({ centerX, centerY, index });
                } else if (selectType.HOLLOW_CIRCLE === this.drawType) {
                    this.moveCircleEnd({
                        centerX,
                        centerY,
                        index,
                        isFilled: false,
                    });
                } else if (selectType.FILL_REACTANGLE === this.drawType) {
                    this.moveRectEnd({ centerX, centerY, index });
                } else if (selectType.HOLLOW_REACTANGLE === this.drawType) {
                    this.moveRectEnd({
                        centerX,
                        centerY,
                        index,
                        isFilled: false,
                    });
                }
            } else if (isFutureFour(index, this.pageSize)) {
                if (
                    selectType.FILL_CIRCLE === this.drawType ||
                    selectType.FILL_REACTANGLE === this.drawType ||
                    selectType.HOLLOW_CIRCLE === this.drawType ||
                    selectType.HOLLOW_REACTANGLE === this.drawType
                ) {
                    // 如果是未来四期，那么就打开数字选择器
                    console.log(index);
                    
                    // 星期不用填写数字
                    // 星期为每行第一列如：180、192、196、198即，180对6取余等于0
                    if (index % 8 !== 0) {
                        // if (hasSelectedBall(this.divDomArr[index])) {
                        // this.numbserSelectorVisible = true;
                        // this.numbserSelectorIndex = index;
                        e.preventDefault();
                        this.moveRectEnd({ centerX, centerY, index });
                        // }
                    }
                }
                const target = this.divDomArr[index];
                const futureFourClass = 'future-four'
                if (target.className.indexOf(futureFourClass) === -1) {
                    target.className += ' ' + futureFourClass
                }
            }
            // 智能线
            if (selectType.INTELLIGENT_LINE === this.drawType) {
                // this.moveCurveEnd({ centerX, centerY, index });
                this.moveCurveEnd({
                    centerX: pageX - oLeft,
                    centerY: pageY - oTop,
                    index,
                });
            } else if (selectType.STRAIGHT_LINE === this.drawType) {
                this.moveLineEnd({ centerX, centerY, index });
                // 自由线结束
            } else if (selectType.FREEDOM_LINE === this.drawType) {
                this.moveFreedomEnd({ centerX, centerY, index });
            }
        },
        toggleDragImg(flag) {
            this.displayDragImg = flag;
            if (this.currentLineType1 === "straightLine") {
                this.displayDragImg = false;
            }
        },
        dragStart() {
            pageLockScroll(true);
        },
        dragImgMove: throttle(function (e) {
            const { changedTouches } = e;
            const { pageX, pageY } = changedTouches[0];
            let imgWR = this.drgImgDom.clientWidth / 2;
            let imgHR = this.drgImgDom.clientHeight / 2;
            // const x = pageX - oLeft;
            const x = pageX - imgWR;
            // const y = pageY - oTop;
            const y = pageY - imgHR;

            this.drgImgDom.style.left = getRem(x);
            this.drgImgDom.style.top = getRem(y - ODragImgTop);
            // const [startX, startY] = this.startXY;
            // const distanceX = Math.abs(startX - pageX);
            // const distanceY = Math.abs(startY - pageY);
            // const _drx = distanceX / 2;
            // const _dry = distanceY / 2;
            // let _x = 0;
            // let _y = 0;
            // if (pageX < startX) {
            //     _x = startX - _drx;
            // } else {
            //     _x = startX + _drx;
            // }
            // if (pageY < startY) {
            //     _y = startY - _dry;
            // } else {
            //     _y = startY + _dry;
            // }
            setCurveQXY({
                line: this.line,
                x: pageX - oLeft,
                y: pageY - oTop,
            });
        }, 40),
        dragEnd() {
            pageLockScroll(false);
        },
        colorHandler(color) {
            this.lineColor = color;
        },
        lineWidthHandler(width) {
            this.lineWidth = width;
        },
        getOffsetTop() {
            const toolDom = document.getElementById("toolId");
            const svgWrapDom = this.$refs.svgRef.$el;
            const { offsetTop, offsetLeft } = svgWrapDom;
            return {
                offsetLeft,
                offsetTop: offsetTop + toolDom.clientHeight,
                offsetDragImgTop: offsetTop,
            };
        },
        backToEdit() {
            this.forSaving = true;
            this.$emit("hidePreviewImg");
        },
        selectNumber(value) {
            let rowIndex = Math.floor(this.numbserSelectorIndex / 8) - this.pageSize;
            const columnIndex = this.numbserSelectorIndex % 8;
            const lastArr = this.dataTwo[this.dataTwo.length - 1];
            rowIndex = lastArr.length - 4 + rowIndex;
            if (columnIndex === 0) {
                lastArr[rowIndex].lotteryVuNew = value;
            } else {
                lastArr[rowIndex][`num${columnIndex}New`] = value;
            }
            this.dataTwo = [...this.dataTwo];
            this.numbserSelectorVisible = false;
        },
        close() {
            this.$router.push("/home");
        },
        // 撤销
        revoke() {
            const item = Step.revoke();
            if (item && typeof item.ball === "number") {
                hideBallSeven(this.divDomArr[item.ball]);
                if (item.innerText) {
                    let rowIndex =
                        Math.floor(item.ball / 8) - this.pageSize;
                    const columnIndex = item.ball % 8;
                    const lastArr = this.dataTwo[this.dataTwo.length - 1];
                    rowIndex = lastArr.length - 4 + rowIndex;
                    delete lastArr[rowIndex][`num${item.ball % 8}New`];
                    this.dataTwo = [...this.dataTwo];
                }
            } else if (item && item.line) {
                removeLine(item.line);
            }
            this.toggleDragImg(false);
        },
        // 恢复
        recovery() {
            const item = Step.recovery();
            if (item && typeof item.ball === "number") {
              if (item.innerText){
                let target = this.divDomArr[item.ball];
                target.className = "future-four";
                showBall(target, {
                  backgroundColor: item.backgroundColor,
                  color: item.color,
                  borderColor: item.borderColor,
                  borderRadius: item.borderRadius,
                });
              } else {
                showBall(this.divDomArr[item.ball], {
                  backgroundColor: item.backgroundColor,
                  color: item.color,
                  borderColor: item.borderColor,
                  borderRadius: item.borderRadius,
                });
              }

                if (item.innerText) {
                    let rowIndex =
                        Math.floor(item.ball / 8) - this.pageSize;
                    const columnIndex = item.ball % 8;
                    const lastArr = this.dataTwo[this.dataTwo.length - 1];
                    rowIndex = lastArr.length - 4 + rowIndex;
                    lastArr[rowIndex][`num${item.ball % 8}New`] =
                        item.innerText;
                    this.dataTwo = [...this.dataTwo];
                }
            } else if (item && item.line) {
                createLineByInstance(item.line);
            }
            this.toggleDragImg(false);
        },
        // 清空
        clear() {
            Step.clear();
            this.divDomArr;
            // 把所有的球都隐藏
            for (let i = 0; i < this.divDomArr.length; i++) {
                if (this.divDomArr[i].className) {
                  hideBallSeven(this.divDomArr[i]);
                }
                // 把未来四期的内容清空
                if (isFutureFour(i, this.pageSize)) {
                    if (i % 8 !== 0) {
                        // hideFutureBall(this.divDomArr[i]);
                        let rowIndex = Math.floor(i / 8) - this.pageSize;
                        const columnIndex = i % 8;
                        // const columnIndex = this.numbserSelectorIndex % 8;
                        const lastArr = this.dataTwo[this.dataTwo.length - 1];
                        rowIndex = lastArr.length - 4 + rowIndex;
                        if (columnIndex === 0) {
                            // lastArr[rowIndex].lotteryVuNew = value;
                        } else {
                            delete lastArr[rowIndex][`num${columnIndex}New`];
                        }
                    }
                }
            }
            this.dataTwo = [...this.dataTwo];
            this.$refs.numbserSelector.clear();
            // 把所有的线删除
            document.getElementById("lineWrap").innerHTML = "";
            this.toggleDragImg(false);
        },
        share() {
            this.toggleDragImg(false);
            this.forSaving = false;
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                mask: true,
                message: "加载中...",
            });
            this.$nextTick(async () => {
                const canvas = await html2canvas(this.allContainerSeven, {
                    x: 0,
                    y: 50,
                });
                this.$emit("showPreviewImg");
                this.$nextTick(() => {
                    convertCanvasToImage(
                        canvas,
                        document.getElementById("imgForDownload")
                    );
                    this.$toast.clear();
                });
            });
        },
        onBack() {
            window.history.pushState(null, null, document.URL);
            this.backToEdit();
        },
        // setLeftBgGreen() {
        //     const firstTh = document.querySelector('#firstTh');
        //     document.querySelector('.td-0-bggreen').style.left = firstTh.clientWidth + 'px';
        // }
    },
    // created() {
    //     let _arr = [];
    //     let a0 = [];
    //     this.data.forEach((item, index) => {
    //         if (index % 4 === 0) {
    //             if (index !== 0) {
    //                 _arr.push([...a0]);
    //             }
    //             a0 = [item];
    //         } else {
    //             a0.push(item);
    //         }
    //     });
    //     if (a0.length > 0) {
    //         _arr.push([...a0]);
    //     }
    //     this.dataTwo = _arr;
    // },
    created() {
        let _arr = [];
        let a0 = [];
        // console.log(this.data);
        
        // this.data.slice(0, this.data.length - 4).forEach((item) => {
        this.data.forEach((item, index) => {
            item.index = index;
            if (item.groupStartLabel === 1) {
                // if (index !== 0) {
                //     _arr.push([...a0]);
                // }
                _arr.push([...a0]);
                a0 = [item];
            } else {
                a0.push(item);
            }
        });
        if (a0.length > 0) {
            _arr.push([...a0]);
        }
        this.dataTwo = _arr;
        // this.dataThree = [this.data.slice(this.data.length - 4)];
        this.dataThree = [];
    },
    mounted() {
        this.allContainerSeven = document.getElementById("allContainerSeven");
        this.drgImgDom = document.getElementById("drgImgDom");
        const { offsetTop, offsetLeft, offsetDragImgTop } = this.getOffsetTop();
        oTop = offsetTop;
        oLeft = offsetLeft;
        ODragImgTop = offsetDragImgTop;
        const svgDom = document.getElementsByClassName("svg-component")[0];
        // const dataLength = this.data.length - 4; // 减去四个空数组
        const dataLength = this.data.length;
        // 获取所有球的中心点的集合
        this.centerXYArr = getCenterXY(
            dataLength,
            8,
            svgDom.clientWidth,
            svgDom.clientHeight
            // toolDom.clientHeight
        );
        // 获取每个球的宽度和高度
        const { itemW, itemH } = getItemWH(
            dataLength,
            8,
            svgDom.clientWidth,
            svgDom.clientHeight
        );
        divW = itemW;
        divH = itemH;
        // 获取divdom集合
        const tableDom = document.getElementById("tableBall");
        this.divDomArr = tableDom.getElementsByTagName("div");
        setTimeout(() => {
            window.scrollTo(0, 1000000);
        }, 200);

        window.history.pushState(null, null, document.URL);
        window.addEventListener("popstate", this.onBack(), false);
        // this.setLeftBgGreen();
    },
    destroyed() {
        window.removeEventListener("popstate", this.onBack, false);
    },
};
</script>

<style lang="less" scoped>
.font-div{
    color:green;
    font-weight: bold;
    white-space: nowrap;
    font-size: 21px;
}
#allContainerSeven{
    background-color: #fff;
}
.mb5 {
    margin-bottom: 5px;
}
// @width: 12.5%;
.left-scroll-div {
    position: absolute;
    top: 0;
    // left: 0;
    width: calc(100vw - (52px * 6));
    bottom: 0;
    left: 0;
    z-index: 100;
    // background-color: red;
    // background-color: yellow;
}
@width: 37.5px;
.td-0-bggreen {
    // background-color: rgba(21, 255, 0, 0.2);
    background-color: rgba(80 ,255, 0 ,0.1);
    // position: absolute;
    // // top: @width + 3px;
    // top: 0;
    // bottom: 0;
    // // right: @width * 7 + @width * 0.26;
    // left: @width *2 - @width *0.3;
    // width: @width + 4px;
}
.td-1-bggreen {
    // background-color: rgba(21, 255, 0, 0.2);
    background-color: rgba(21, 255, 0, 0.2);
    position: absolute;
    // top: @width + 3px;
    top: 0;
    bottom: 0;
    right: 0;
    // width: @width * 3 + @width * 0.16;
    width: (@width + 1px) * 3;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.drawing-rules {
    width: 100vw;
    // height: 100vh;
    // overflow: auto;
    outline: 1px solid #90c282;
    .light-purple {
        // background-color: #ded5f5;
        background-color: #f5f1ff;
    }
    .text-white {
        color: #fff;
    }
    .out-white {
        outline: 1px solid #fff;
    }
    .purple {
        background-color: #90c282;
    }
    .w1 {
        width: @width;
    }
    .w2 {
        width: @width * 2;
    }
    .w100 {
        width: 100%;
    }
    text-align: center;
    table {
        border-collapse: collapse;
    }
    // thead.title {
    //     color: #fff;
    //     font-size: 24px;
    //     font-weight: bold;
    // }
    thead th {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        // border: 0.5px solid #fff;
        outline: 1px solid #fff;
    }
    tbody tr:nth-child(4) {
        td {
            // border-bottom: 3px solid #90c282;
            border-bottom-width: 2px;
        }
        td:nth-child(1) {
            // border-bottom: 3px solid #90c282;
            border-bottom: 2px solid #fff;
        }
    }
    tbody tr td {
        font-weight: bold;
    }
    tbody tr td:nth-child(1),
    tbody tr td:nth-child(2) {
        font-weight: 400;
    }
    tbody tr:nth-child(1) {
        td {
            // border-top: 3px solid #90c282;
            border-top-width: 2px;
        }
        td:nth-child(1) {
            // border-top: 3px solid #90c282;
            // border-top: 1px solid #fff;
        }
    }
    th,
    td {
        border: 1px solid #90c282;
        // outline: 1px solid #90c282;
        font-size: 16px;
        // font-weight: 400;
        height: @width;
        position: relative;
        z-index: 11;
        text-align: center;
        position: relative;
    }
    .first-td {
        .purple;
        .text-white;
        .out-white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .first-td-span{
            font-size: 14px;
        }
        span{
            font-size: 10px;
            font-weight: bold;
            white-space: nowrap;
        }
    }
    .td-second{
        span{
            white-space: nowrap;
        }
        .td-second-one{
            font-size: 10px;
        }
        .td-second-two{
            font-size: 10px;
        }
    }
    .dr-canvas-wrap {
        position: absolute;
        // left: @width * 2;
        width: @width * 8 + 12;
        right: 0;
        top: @width;
        // bottom: 132px;
        bottom: 0;
        // background-color: red;
    }
    td > div {
        width: 100%;
        // height: 100%;
        // height: 46px;
        // line-height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        word-break: break-all;
    }
    td span {
        font-size: 12px;
        // bottom: 0;
        // left: 0;
        // position: absolute;
    }
    .ball {
        width: @width * 0.65;
        height: @width * 0.65;
        // line-height: @width * 0.9;
        margin: 0 auto;
        border-radius: 50%;
        background-color: #7e5fff;
        color: #fff;
        border: 2px solid #fff;
        box-shadow: 0 0 5px #888888;
        font-size: 21px;
    }
    .ballOpacity {
        width: @width * 0.65;
        height: @width * 0.65;
        // line-height: @width * 0.9;
        margin: 0 auto;
        border-radius: 50%;
        // background-color: #7e5fff;
        color: #fff;
        opacity: 0.5;
        border: 2px solid #fff;
        box-shadow: 0 0 10px #888888;
    }
    .dr-drages-icon {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 110;
        background-color: rgba(0, 0, 0, 0.1);
    }
}
.back-btn {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    width: 100%;
    // background-color: rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px;
    span {
        font-size: 24px;
    }
}

#tableBall .future-four {
    width: 94%;
    height: 94%;
    position: relative;
    left: -1px;
    font-size: 15px;
    background-color: #7e5fff !important;
}
</style>